<template>
  <div class="min-h-screen bg-gray-100">
    <div class="pb-32">
      <Nav />
      <header v-if="$route.meta.header" class="pb-5 pt-24">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-primary">{{ $route.name }}</h1>
        </div>
      </header>
    </div>
    <main :class="{'-mt-32': $route.meta.header}">
      <slot></slot>
    </main>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {
    Nav: () => import('./Nav'),
    Footer: () => import('./Footer'),
  }
}
</script>